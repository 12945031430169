import React, { useState, useEffect } from "react";

const PrivacyPolicy = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    fetch("/privacypolicy.html")
      .then((response) => response.text())
      .then((content) => setHtmlContent(content))
      .catch((err) => console.error("Failed to load HTML:", err));
  }, []);

  return (
    <div
      className="privacy-policy"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export default PrivacyPolicy;
